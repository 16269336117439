export const withHubSpotFormPages = [
  {
    title: "We're Celebrating What's Next for You!",
    portalId: '4090950',
    formId: '7ca5c3dd-3e0f-4fc2-87d4-a4a631bd5d5f',
  },
  {
    title: 'Now, condo living is can-do living in Taft Avenue, Pasay City',
    portalId: '4090950',
    formId: 'fdd00cd4-074c-4952-a5b1-7a2cb31f11ee',
  },
  {
    title: 'Move-in Now to Your New Avida Home',
    portalId: '4090950',
    formId: '7ca5c3dd-3e0f-4fc2-87d4-a4a631bd5d5f',
  },
]
