import { ImageDataLike } from 'gatsby-plugin-image'

type Banner = {
  image: string
  videoLink: string
}

type GetBannerDataReturnTypes = {
  type: 'image' | 'video' | 'optimized'
  url?: string
  image?: ImageDataLike
} | null

const getBannerData = (
  optimizedImg?: ImageDataLike,
  videoLink?: string,
  banner?: Banner,
): GetBannerDataReturnTypes => {
  if (banner) {
    if (banner.videoLink) {
      return {
        type: 'video',
        url: banner.videoLink,
      }
    }
    if (banner.image) {
      return {
        type: 'image',
        url: banner.image,
      }
    }
  }

  if (videoLink) {
    return {
      type: 'video',
      url: videoLink,
    }
  }

  if (optimizedImg) {
    return {
      type: 'optimized',
      image: optimizedImg,
    }
  }

  return null
}

export default getBannerData
