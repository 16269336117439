import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import './NewsAndEvents.scss'
import ReactPlayer from 'react-player'
import loadable from '@loadable/component'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'
import Layout from '../../layouts/Layout/Layout'
import { PreviousNextTypes } from '../../components/ArticleNavigation/ArticleNavigation'
import getBannerData from '../../services/getBannerData'
import Seo from '../../components/Seo/Seo'
import { withHubSpotFormPages } from './data/NewsAndEventsData'

const ComponentForm = loadable(() =>
  import(
    '../../components/ContentComponents/components/ComponentForm/ComponentForm'
  ),
)

const ArticleNavigation = loadable(() =>
  import('../../components/ArticleNavigation/ArticleNavigation'),
)

const ContentComponents = loadable(() =>
  import('../../components/ContentComponents/ContentComponents'),
)

const ArticleLayout = loadable(() =>
  import('../../layouts/ArticleLayout/ArticleLayout'),
)

type ArticleTypes = {
  seoTitle?: string
  seoDescription?: string
  title: string
  subtitle: string
  category: string
  featuredImage: string
  videoLink: string
  optimizedFeaturedImg: ImageDataLike
  slug: string
  datePublished: string
  shortDescription: string
  homeBanner?: {
    image: string
    videoLink: string
  }
  contents: {
    title?: string
    description?: string
    message?: string
    type: string
    video?: string
    caption?: string
    caption1?: string
    caption2?: string
    caption3?: string
    caption4?: string
    optimizedContentImg?: ImageDataLike
    optimizedContentImg1?: ImageDataLike
    optimizedContentImg2?: ImageDataLike
    optimizedContentImg3?: ImageDataLike
    optimizedContentImg4?: ImageDataLike
  }[]
  tags: string[]
}

type RecentArticleTypes = {
  id: string
  title: string
  slug: string
  category: string
  datePublished: string
  featuredImage: string
  optimizedFeaturedImg: ImageDataLike
  tags: string[]
}

type MainPropTypes = {
  data: {
    newsEventsData: ArticleTypes
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
  pageContext: {
    previous?: PreviousNextTypes
    next?: PreviousNextTypes
  }
}

const asideNavItems = [
  {
    url: 'news-and-events/news/',
    label: 'News',
    category: 'news',
  },
  {
    url: 'news-and-events/events/',
    label: 'Events',
    category: 'events',
  },
  {
    url: 'news-and-events/awards-and-recognition/',
    label: 'Awards & Recognition',
    category: 'awards',
  },
]

const NewsAndEvents = ({
  data: {
    newsEventsData,
    recentArticles: { nodes: recentArticles },
  },
  pageContext: { previous, next },
}: MainPropTypes): ReactElement => {
  const [bannerMedia] = useState<{
    url?: string
    type: 'image' | 'video' | 'optimized'
    image?: ImageDataLike
  } | null>(
    getBannerData(
      newsEventsData?.optimizedFeaturedImg,
      newsEventsData?.videoLink,
      newsEventsData?.homeBanner,
    ),
  )

  // eslint-disable-next-line consistent-return
  const getHubSpotForm = (): JSX.Element | undefined => {
    const currentArticle = newsEventsData.title
    const hasHubSpotForm = withHubSpotFormPages.find((page) => {
      return page.title === currentArticle
    })

    if (hasHubSpotForm) {
      const { portalId, formId } = hasHubSpotForm
      return <ComponentForm portalId={portalId} formId={formId} />
    }
  }

  const seoTitle =
    newsEventsData.seoTitle && newsEventsData.seoTitle !== 'no content'
      ? newsEventsData.seoTitle
      : newsEventsData.title

  const seoDescription =
    newsEventsData.seoDescription &&
    newsEventsData.seoDescription !== 'no content'
      ? newsEventsData.seoDescription
      : newsEventsData.shortDescription

  return (
    <Layout navButtonVariant="back">
      <Seo
        title={seoTitle}
        ogMetaData={{
          title: seoTitle,
          description: seoDescription,
          image: newsEventsData.featuredImage,
        }}
        jsonData={{
          '@type': 'Article',
          description: seoDescription,
          keywords: newsEventsData.tags.join(', '),
          image: newsEventsData.featuredImage,
          url: `${process.env.GATSBY_SITE_URL}/news-and-events/${newsEventsData.slug}/`,
        }}
      />
      <ArticleLayout
        title="Categories"
        asideNavItems={asideNavItems}
        breadcrumbs={[
          {
            label: newsEventsData.category,
          },
        ]}
        recentArticles={recentArticles
          ?.filter((article) => !article.tags?.includes('banner'))
          ?.slice(0, 3)}
        displayRecentArticlesMobile
        contentClassName="news-events"
      >
        <article className="news-events">
          {bannerMedia && (
            <>
              {bannerMedia.type === 'video' ? (
                <ReactPlayer
                  url={bannerMedia.url}
                  width="100%"
                  height="100%"
                  className="pursuit-of-passion-banner"
                />
              ) : null}
              {bannerMedia.type === 'optimized' ? (
                <GatsbyImage
                  image={getImage(bannerMedia.image!)!}
                  alt="image"
                />
              ) : (
                <LazyLoadImage
                  className="news-events-banner"
                  src={bannerMedia.url}
                  alt="banner image"
                />
              )}
            </>
          )}
          <div className="news-events-container">
            <span className="news-events-container-type">
              {newsEventsData.category}
            </span>
            <h2 className="news-events-container-title">
              {newsEventsData.title}
            </h2>
            <p className="news-events-container-date">
              {newsEventsData.datePublished}
            </p>
            <div className="news-events-container-content">
              <ContentComponents contents={newsEventsData.contents} />
              {getHubSpotForm()}
            </div>
          </div>
        </article>
      </ArticleLayout>
      <ArticleNavigation
        title={newsEventsData.title}
        slug={newsEventsData.slug}
        previous={previous}
        next={next}
      />
    </Layout>
  )
}
export default NewsAndEvents

export const pageQuery = graphql`
  query NewsAndEventsQuery($id: String!) {
    newsEventsData: newsAndEvents(id: { eq: $id }) {
      ...NewsAndEventsPageArticleFields
    }
    recentArticles: allNewsAndEvents {
      nodes {
        ...NewsAndEventsPageRecentArticleFields
      }
    }
  }
`
